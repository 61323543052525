<template>
  <div
    v-if="files && files.length > 0"
    class="fixed right-[430px] top-0 h-screen w-[900px] border-l bg-white px-4"
  >
    <ScrollArea
      class="-mt-4 min-w-[calc(100%-2px)] whitespace-nowrap rounded-md"
    >
      <div class="flex w-max items-center space-x-4 pb-5 pt-7">
        <div
          v-for="file in files"
          :key="file?.name"
          class="relative h-[86px] w-[142px]"
        >
          <div
            class="relative h-[86px] w-[142px] overflow-hidden rounded-lg border-[1.5px] border-slate-200"
            @click="() => handleSelectedFile(file)"
          >
            <img
              v-if="file?.type === 'image/png' || file?.type === 'image/jpeg'"
              :src="getFileUrl(file)"
              :alt="file?.name"
              class="h-full w-full rounded-[7px] object-cover"
            />
            <div v-else-if="file?.type === 'application/pdf'" class="h-14">
              <div class="flex h-full w-full items-center justify-center">
                <the-icon
                  icon-name="pdf-file"
                  size="l"
                  class-name="fill-none"
                />
              </div>
            </div>
            <div v-else-if="file?.type === 'text/csv'" class="h-14">
              <div class="flex h-full w-full items-center justify-center">
                <the-icon
                  icon-name="csv-file"
                  size="l"
                  class-name="fill-none"
                />
              </div>
            </div>
            <div
              v-else-if="
                file?.type ===
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              "
              class="h-14"
            >
              <div class="flex h-full w-full items-center justify-center">
                <the-icon
                  icon-name="doc-file"
                  size="l"
                  class-name="fill-none"
                />
              </div>
            </div>
            <div v-else class="h-14">
              <div class="flex h-full w-full items-center justify-center">
                <the-icon
                  icon-name="page-file"
                  size="l"
                  class-name="fill-none"
                />
              </div>
            </div>
            <div
              class="absolute bottom-0 left-0 z-10 flex h-6 w-full items-center rounded-b-lg bg-slate-50 px-2 text-xs font-medium text-slate-700"
            >
              <button
                type="button"
                class="w-full truncate border-none outline-none"
              >
                {{ file?.name || '-' }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ScrollBar orientation="horizontal" class="bg-gray-100" />
    </ScrollArea>
    <div
      v-if="selectedFile"
      class="my-4 hidden h-[700px] max-h-[700px] w-full overflow-x-hidden bg-[#F8F9FA] p-4 lg:block"
    >
      <div
        v-if="
          selectedFile.type === 'image/png' ||
          selectedFile.type === 'image/jpeg'
        "
        class="h-full w-full bg-white"
      >
        <img
          :src="getFileUrl(selectedFile)"
          :alt="selectedFile.name"
          class="h-full w-full rounded-lg bg-white object-cover"
        />
      </div>
      <div
        v-else-if="selectedFile.type === 'application/pdf'"
        class="h-full w-full bg-white"
      >
        <vue-pdf-embed :source="getFileUrl(selectedFile) || ''" />
      </div>
      <div
        v-else
        class="flex h-full w-full items-center justify-center bg-white"
      >
        Cannot display file
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import VuePdfEmbed from 'vue-pdf-embed'
import { getFileUrl } from '~/lib/utils'
import 'vue-pdf-embed/dist/style/index.css'
import TheIcon from '@/components/shared/the-icon.vue'

const props = defineProps<{ files: (File | null)[] }>()
const selectedFile = ref<File | null>(null)

const handleSelectedFile = (file: File) => {
  selectedFile.value = file
}

onMounted(() => {
  if (props.files.length) {
    selectedFile.value = props.files[0]
  }
})
</script>
