import insp, { install } from 'inspectlet-es'
interface CustomData {
  email?: string
  preferred_username?: string
  person_id?: string
}
export function useInspectlet() {
  const config = useRuntimeConfig()
  if (config.public.OIDC_USER !== 'oidc.user:https://sts.lenkie.com:web-app')
    return
  const inspectletId: number = 643257092
  install(inspectletId)

  const jwtToken: string | null =
    localStorage.getItem(config.public.OIDC_USER) || null

  let customData: CustomData | null = null

  if (jwtToken) {
    const parseJwt = (token: string): CustomData | null => {
      try {
        return JSON.parse(atob(token.split('.')[1]))
      } catch (e) {
        return null
      }
    }

    customData = parseJwt(jwtToken)
  }

  const email = customData?.email
  insp(['identify', `${email}`])

  insp([
    'tagSession',
    {
      email: customData?.email,
      userName: customData?.preferred_username,
      personId: customData?.person_id,
    },
  ])
}
