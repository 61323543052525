<template>
  <ScrollArea class="h-[calc(100vh-236px)]">
    <div class="ml-4 mt-4 w-[calc(100%-30px)] sm:w-[402px] lg:ml-0 lg:pl-6">
      <form class="grid grid-cols-1 gap-y-5">
        <div class="">
          <Label
            for="bank-country"
            class="mb-1 text-sm font-medium text-primary"
            >Bank country / region</Label
          >
          <Select
            v-model="form.account.country.code"
            data-test-id="region-select"
          >
            <SelectTrigger class="">
              <SelectValue placeholder="Select bank country / region" />
            </SelectTrigger>

            <SelectContent>
              <SelectGroup>
                <ScrollArea class="h-[300px]">
                  <SelectItem
                    v-for="country in listOfCountries"
                    :key="country.id"
                    :value="country.code"
                    :data-test-id="`region-${country.code}`"
                  >
                    {{ country.name }}
                  </SelectItem>
                </ScrollArea>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div>
          <Label
            for="bank-country"
            class="mb-1 text-sm font-medium text-primary"
            >Account currency</Label
          >
          <Select
            v-model="form.account.currency.code"
            data-test-id="currency-select"
          >
            <SelectTrigger class="">
              <SelectValue placeholder="Select account currency" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem
                  v-for="(currency, index) in currencies"
                  :key="index"
                  :value="currency"
                  @click.prevent="setAccountCurrency(currency)"
                >
                  {{ currency }}
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <template v-if="transferMethod?.length != 0">
          <div>
            <Label
              for="bank-country"
              class="mb-1 text-sm font-medium text-primary"
              >Transfer method</Label
            >
            <Select v-model="selectedPaymentMethod">
              <SelectTrigger class="">
                <SelectValue placeholder="Select account currency" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem
                    v-for="(tf, ix) in transferMethod"
                    :key="ix + 'f'"
                    :value="tf.value"
                    @click="
                      selectTransferMethodForMultiplePaymentOption(tf.value)
                    "
                  >
                    {{
                      tf.value === 'LOCAL'
                        ? 'Domestic bank transfer'
                        : 'International bank transfer'
                    }}
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </template>
        <template v-if="selectedPaymentMethod === 'SWIFT'">
          <Label>
            <div
              class="flex cursor-pointer items-center gap-x-2 rounded-md border border-[#E2E8F0] p-4 pl-3 text-primary"
            >
              <div>
                <p class="pb-1 text-sm font-bold text-primary">SWIFT</p>

                <div class="text-xs text-[#475467]">
                  <p class="pb-1 text-xs text-[#6B7280]">0-1 business day(s)</p>
                  <p class="text-xs text-[#6B7280]">£15.00 transfer fee</p>
                </div>
              </div>
            </div>
          </Label>
        </template>
        <template v-if="clearingSystemAvailable.length != 0">
          <label
            v-for="(cs, index) in clearingSystemAvailable?.field?.options"
            :key="index"
          >
            <div
              v-if="cs.value !== 'PAPER_CHECK'"
              :class="cSelected === cs.value ? 'bg-[#EDF5FF]' : 'bg-#FAFCFF '"
              class="flex cursor-pointer items-center gap-x-3 rounded-md border bg-white p-4 pl-3 text-primary"
            >
              <div class="gap-x-3">
                <input
                  :id="cs.value"
                  type="radio"
                  name="smae-here"
                  calss=""
                  @input="handleOptionChange(cs)"
                />
                <span class="text-blue-dark pl-2 text-sm font-medium">
                  {{
                    cs.label === 'FASTER_PAYMENTS'
                      ? 'Faster Payments'
                      : cs.label
                  }}</span
                >
                <p
                  v-if="cs.value === 'FASTER_PAYMENTS'"
                  class="text-sm text-[#6B7280]"
                >
                  <span class="text-sx pl-5 text-[#6B7280]">{{
                    cs.processing_time
                  }}</span>
                </p>
                <div
                  v-if="cs.value === 'ACH'"
                  class="pl-5 text-sm text-[#475467]"
                >
                  <p class="text-xs font-medium text-[#6B7280]">
                    {{ cs.processing_time }}
                  </p>
                  <p class="text-xs font-medium text-[#6B7280]">
                    Transfer fee: $0.5
                  </p>
                </div>
                <div
                  v-if="cs.value === 'FEDWIRE'"
                  class="pl-5 text-sm text-[#475467]"
                >
                  <p class="text-xs font-medium text-[#6B7280]">
                    {{ cs.processing_time }}
                  </p>

                  <p class="text-xs font-medium text-[#6B7280]">
                    Transfer fee: $7.00
                  </p>
                </div>
                <div v-if="cs.value === 'SWIFT'" class="text-sm text-[#475467]">
                  <p>{{ cs.processing_time }}</p>
                </div>
              </div>
            </div>
          </label>
        </template>
        <template v-if="dynamicInput.length != 0">
          <template v-for="f in dynamicInput" :key="f.key">
            <template
              v-if="
                f.field.key === 'account_number' ||
                f.field.key === 'account_routing_value1' ||
                f.field.key === 'account_routing_value2' ||
                f.field.key === 'swift_code' ||
                f.field.key === 'iban' ||
                f.field.key === 'account_name'
              "
            >
              <div>
                <Label
                  :for="f.field.key"
                  class="mb-1 text-sm font-medium text-primary"
                  >{{
                    f.field.label === 'Aba'
                      ? `${routingAba} routing number`
                      : f.field.label
                  }}</Label
                >
                <template
                  v-if="
                    f.field.type === 'INPUT' && f.field.key === 'account_name'
                  "
                >
                  <Input
                    id="name"
                    v-model="formData[f.field.key]"
                    type="text"
                    focus
                    name="name"
                    :placeholder="f.field.label"
                    @input="getCountryCode(f)"
                  />
                </template>

                <template
                  v-if="
                    f.field.type === 'INPUT' && f.field.key !== 'account_name'
                  "
                >
                  <Input
                    :id="f.field.key"
                    v-model="formData[f.field.key]"
                    :type="formData[f.field.type]"
                    :placeholder="f.field.label"
                  />
                  <span
                    v-if="f.field.key === 'street_address'"
                    class="text-blue-dark text-[11px] font-normal"
                    >Recipient's address (Should not be a PO or GPO box
                    address)</span
                  >
                </template>
              </div>
            </template>
          </template>
        </template>
        <template v-if="dynamicInput.length != 0">
          <div>
            <div
              class="mb-4 flex cursor-pointer items-center justify-between space-x-1"
            >
              <p class="text-lg font-bold text-primary">Vendor details</p>
            </div>

            <div>
              <div
                v-for="f in dynamicInput"
                :key="f.key"
                :class="
                  f.field.key === 'account_routing_type1' ||
                  f.field.key === 'entity_type' ||
                  f.field.key === 'payment_method' ||
                  f.field.key === 'account_routing_type2'
                    ? 'hidden'
                    : 'items-center'
                "
              >
                <div>
                  <template
                    v-if="
                      f.field.key !== 'account_number' &&
                      f.field.key !== 'account_routing_value1' &&
                      f.field.key !== 'account_routing_value2' &&
                      f.field.key !== 'swift_code' &&
                      f.field.key !== 'swift_code' &&
                      f.field.key !== 'iban' &&
                      f.field.key !== 'account_name'
                    "
                  >
                    <div class="mb-6">
                      <Label
                        class="mb-1 text-sm font-medium text-primary"
                        :for="f.field.key"
                        >{{ f.field.label }}</Label
                      >

                      <Input
                        v-if="f.field.type === 'INPUT'"
                        :id="f.field.key"
                        v-model="formData[f.field.key]"
                        :type="formData[f.field.type]"
                        :placeholder="f.field.label"
                      />
                      <span
                        v-if="f.field.key === 'street_address'"
                        class="text-blue-dark text-[11px] font-normal"
                        >Recipient's address (Should not be a PO or GPO box
                        address)</span
                      >

                      <Select
                        v-if="f.field.type === 'SELECT'"
                        v-model="formData[f.field.key]"
                      >
                        <SelectTrigger class="">
                          <SelectValue
                            :placeholder="`Select ${f.field.label}`"
                          />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup
                            v-for="(ty, iy) in f.field.options"
                            :key="iy + 'f'"
                          >
                            <SelectItem
                              :value="ty.value"
                              @click="selectedRegion(ty.value)"
                            >
                              {{ ty.label }}
                            </SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                  </template>
                </div>
              </div>

              <div class="mb-10">
                <Label
                  for="invoice"
                  class="col-span-6 pt-3 text-[14px] font-medium leading-[24px] text-primary sm:col-span-3"
                  >Proof of account<span>&#42;</span>
                </Label>
                <UploadsFileUploader
                  accept="application/pdf,.docx,.csv"
                  @files="onDrop"
                >
                  <template v-if="files.length > 0" #accept-text>
                    {{ `${files.length} statement(s) attached` }}
                  </template>
                </UploadsFileUploader>
              </div>

              <div
                v-if="props.errorMessage"
                class="p5 my-3 border-l-4 border-red-500 bg-orange-100 bg-opacity-10"
              >
                <p class="text-blue-dark p-3 pl-5 text-[13px]">
                  {{ props.errorMessage }}
                </p>
              </div>
            </div>
            <!-- <Button
              :disabled="isLoading || !canSubmit"
              :class="
                isLoading || !canSubmit
                  ? 'bg-[#C2C3C4] text-white'
                  : 'bg-blue-dark text-white'
              "
              type="submit"
              class="flex items-center py-[14px] px-12 rounded-lg w-full justify-center text-center mt-6"
            >
              Create supplier
          </Button> -->
          </div>
        </template>
        <div v-if="isFetching" class="loader flex justify-center">
          <svg
            aria-hidden="true"
            class="mr-2 h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="#1A2D5B"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </form>
    </div>
  </ScrollArea>
</template>

<script setup lang="ts">
import _ from 'lodash'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useCountries } from '@/stores/countries'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useProfileStore } from '~/stores/profile'

const { $lenkieBankingApi } = useNuxtApp()

const form = ref({
  name: '',
  trading_name: '',
  account: {
    bank: {
      name: '',
      country: {
        id: '',
        code: '',
        name: '',
      },
    },
    country: {
      id: '',
      code: '',
    },
    currency: {
      code: '',
    },
    nonuk_bank_account_details: {
      iban: '',
      swift_code: '',
    },
    uk_bank_details: {
      account_number: '',
      sort_code: '',
    },
    international_bank_account_details: {
      iban: '',
      swift_code: '',
    },
    fedwire_bank_account_details: {
      routing_number: '',
      account_number: '',
    },
    ach_bank_details: {
      routing_number: '',
      account_number: '',
    },
  },
  address: {
    street_address: '',
    postal_code: '',
    town: '',
    state: '',
    country: {
      id: '',
      code: '',
    },
  },
})

const countriesWithEurosCurrency = ref([
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
])

interface IProps {
  errorMessage: string
}

const countriesWithPoundsCurrency = ref(['GB'])

const formData = ref<any>({})

const countries = useCountries()

const currencies = ['GBP', 'USD', 'EUR']

const listOfCountries = computed(() => countries.data || [])

const profile = useProfileStore()

const emits = defineEmits(['child-option'])

const props = defineProps<IProps>()

// const allCountries = computed(() => countries.allCountries || [])
// interface ITransferMethod {
//   label: string
//   value: string
//   payment_limit: string
//   processing_time: string
// }

const selectedCountry = ref()
const isFetching = ref(false)

const selectCountry = ref()
const transferMethod = ref([])
const dynamicInput = ref([])
const clearingSystemAvailable = ref([])
const selectCurrency = ref()
const selectedPaymentMethod = ref()
const routingAba = ref()
const cSelected = ref()
const selectedClearingSystem = ref()
// const errorMessage = ref()
const canSubmit = ref(false)
const isLoading = ref(false)
const invoiceUrl = ref('')

const files = ref([])
async function onDrop(acceptFiles: any) {
  files.value = acceptFiles
  try {
    const totalFiles = files.value.length
    if (totalFiles > 0) {
      const formDa = new FormData()
      for (let i = 0; i < totalFiles; i++) {
        formDa.append('files', files.value[i])
      }
      const { status, data } = await $lenkieBankingApi.post(
        `/FileUpload/organisation/${profile?.data?.organisation?.id}/upload/fund-request`,
        formDa,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      if (status === 200) {
        const allFiles = await data.result.relative_paths.join(',')
        invoiceUrl.value = allFiles
        canSubmit.value = true
        const dynamicform = {
          dynamic_input: dynamicInput.value,
          selected_country: selectCountry.value,
          selected_currency: selectCurrency.value,
          selected_payment_method: selectedPaymentMethod.value,
          selected_clearing_system: selectedClearingSystem.value,
          form_data: formData.value,
          invoice_url: invoiceUrl.value,
        }
        emits('child-option', dynamicform)
      }
    }
  } catch (error) {
    canSubmit.value = false
  }
}

watch(
  () => form.value.account.country.code,
  (newValue) => {
    if (newValue) {
      setBankSelectedCountry(form.value.account.country.code)
    }
  },
)

watch(
  () => form.value.account.currency.code,
  (newValue) => {
    if (newValue) {
      setAccountCurrency(form.value.account.currency.code)
    }
  },
)

watch(
  () => selectedPaymentMethod.value,
  (newValue) => {
    if (newValue) {
      selectTransferMethodForMultiplePaymentOption(selectedPaymentMethod.value)
    }
  },
)

const setBankSelectedCountry = async (value: string) => {
  try {
    selectedCountry.value = value
    isFetching.value = true
    selectCountry.value = value
    transferMethod.value = []
    dynamicInput.value = []
    clearingSystemAvailable.value = []
    if (countriesWithEurosCurrency.value.includes(value)) {
      selectCurrency.value = 'EUR'
      form.value.account.currency.code = selectCurrency.value
    } else if (countriesWithPoundsCurrency.value.includes(value)) {
      selectCurrency.value = 'GBP'
      form.value.account.currency.code = selectCurrency.value
    } else {
      selectCurrency.value = 'USD'
      form.value.account.currency.code = selectCurrency.value
    }
    const options = {
      account_currency: selectCurrency.value,
      bank_country_code: selectCountry.value,
      entity_type: 'COMPANY',
    }
    const { data } = await $lenkieBankingApi.post(
      '/Beneficiaries/generate-beneficiary-schema',
      options,
    )
    if (data) {
      const paymentMethods = _.find(data.result.fields, {
        key: 'payment_method',
      })
      if (paymentMethods) {
        if (paymentMethods.field.options.length > 1) {
          transferMethod.value = paymentMethods.field.options
          Object.assign(options, {
            payment_method: paymentMethods.field.default,
          })

          selectedPaymentMethod.value = paymentMethods.field.default

          const { data } = await $lenkieBankingApi.post(
            '/Beneficiaries/generate-beneficiary-schema',
            options,
          )
          const filteredClearingSytem = _.filter(data.result.fields, (obj) => {
            isFetching.value = false
            return obj.field.key === 'local_clearing_system'
          })

          if (filteredClearingSytem.length) {
            clearingSystemAvailable.value = filteredClearingSytem[0]
            isFetching.value = false
          } else {
            Object.assign(options, {
              payment_method: paymentMethods.field.default,
            })

            selectedPaymentMethod.value = paymentMethods.field.default
            const { data } = await $lenkieBankingApi.post(
              '/Beneficiaries/generate-beneficiary-schema',
              options,
            )
            const filteredObjects = _.filter(data.result.fields, (obj) => {
              return (
                (obj.field.type === 'INPUT' || obj.field.type === 'SELECT') &&
                obj.required === true &&
                obj.field.key !== 'bank_country_code' &&
                obj.field.key !== 'account_currency'
              )
            })
            isFetching.value = false
            dynamicInput.value = filteredObjects
          }
        } else {
          Object.assign(options, {
            payment_method: paymentMethods.field.default,
          })
          selectedPaymentMethod.value = paymentMethods.field.default
          const { data } = await $lenkieBankingApi.post(
            '/Beneficiaries/generate-beneficiary-schema',
            options,
          )

          if (data) {
            const filteredObjects = _.filter(data.result.fields, (obj) => {
              return (
                (obj.field.type === 'INPUT' || obj.field.type === 'SELECT') &&
                obj.required === true &&
                obj.field.key !== 'bank_country_code' &&
                obj.field.key !== 'account_currency'
              )
            })
            isFetching.value = false
            dynamicInput.value = filteredObjects
          }
        }
      }
    } else {
      isFetching.value = false
    }
  } catch (error) {
    isFetching.value = false
  }
}

const setAccountCurrency = async (value: string) => {
  try {
    isFetching.value = true
    transferMethod.value = []
    dynamicInput.value = []
    clearingSystemAvailable.value = []
    selectCurrency.value = value
    const options = {
      account_currency: selectCurrency.value,
      bank_country_code: selectCountry.value,
      entity_type: 'COMPANY',
    }
    const { data } = await $lenkieBankingApi.post(
      '/Beneficiaries/generate-beneficiary-schema',
      options,
    )
    if (data) {
      const paymentMethods = _.find(data.result.fields, {
        key: 'payment_method',
      })
      if (paymentMethods) {
        if (paymentMethods.field.options.length > 1) {
          transferMethod.value = paymentMethods.field.options
          isFetching.value = false
        } else {
          Object.assign(options, {
            payment_method: paymentMethods.field.default,
          })
          selectedPaymentMethod.value = paymentMethods.field.default
          const { data } = await $lenkieBankingApi.post(
            '/Beneficiaries/generate-beneficiary-schema',
            options,
          )

          const filteredObjects = _.filter(data.result.fields, (obj) => {
            isFetching.value = false
            return (
              (obj.field.type === 'INPUT' || obj.field.type === 'SELECT') &&
              obj.required === true &&
              obj.field.key !== 'bank_country_code' &&
              obj.field.key !== 'account_currency'
            )
          })
          dynamicInput.value = filteredObjects
        }
      }
    }
  } catch (error) {}
}
const selectTransferMethodForMultiplePaymentOption = async (value: string) => {
  try {
    selectedPaymentMethod.value = value
    clearingSystemAvailable.value = []
    dynamicInput.value = []
    const options = {
      account_currency: selectCurrency.value,
      bank_country_code: selectCountry.value,
      entity_type: 'COMPANY',
      payment_method: selectedPaymentMethod.value,
    }
    const { data } = await $lenkieBankingApi.post(
      '/Beneficiaries/generate-beneficiary-schema',
      options,
    )
    const filteredClearingSytem = _.filter(data.result.fields, (obj) => {
      return obj.field.key === 'local_clearing_system'
    })
    if (filteredClearingSytem.length) {
      clearingSystemAvailable.value = filteredClearingSytem[0]
      return
    }
    if (data) {
      const filteredObjects = _.filter(data.result.fields, (obj) => {
        return (
          (obj.field.type === 'INPUT' || obj.field.type === 'SELECT') &&
          obj.required === true &&
          obj.field.key !== 'bank_country_code' &&
          obj.field.key !== 'account_currency'
        )
      })

      dynamicInput.value = filteredObjects
    }
  } catch (error) {
    isFetching.value = false
    isLoading.value = false
  }
}

const handleOptionChange = async (val: any) => {
  try {
    isFetching.value = true
    routingAba.value = val.label
    cSelected.value = val.value
    selectedClearingSystem.value = val.value
    const options = {
      account_currency: selectCurrency.value,
      bank_country_code: selectCountry.value,
      entity_type: 'COMPANY',
      payment_method: selectedPaymentMethod.value,
      local_clearing_system: selectedClearingSystem.value,
    }
    const { data } = await $lenkieBankingApi.post(
      '/Beneficiaries/generate-beneficiary-schema',
      options,
    )
    const filteredObjects = _.filter(data.result.fields, (obj) => {
      return (
        (obj.field.type === 'INPUT' || obj.field.type === 'SELECT') &&
        obj.required === true &&
        obj.field.key !== 'bank_country_code' &&
        obj.field.key !== 'account_currency'
      )
    })
    isFetching.value = false
    dynamicInput.value = filteredObjects
  } catch (error) {
    isFetching.value = false
    isLoading.value = false
  }
}

const getCountryCode = (f: any) => {
  formData.value.company_name = formData.value[f.key]
  formData.value.country_code = selectedCountry.value
}
const selectedRegion = (_val: string) => {}

onMounted(() => {
  countries.getCountries()
  countries.getAllCountries()
})
</script>
