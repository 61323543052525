<template>
  <ScrollArea class="h-[calc(100vh-236px)]">
    <div class="ml-0 mt-4 min-h-screen overflow-auto px-5 pb-40 lg:ml-0">
      <div
        v-if="
          ocrData !== null ||
          (selectedBill?.person &&
            selectedBill?.person?.name &&
            selectedBill?.person?.name?.toLowerCase() === 'email' &&
            selectedBill.beneficiary?.allowed_payment_types === null)
        "
        class="pb-3"
      >
        <div
          class="w-full rounded-sm bg-blue-200 px-2 py-1 text-center text-xs font-medium text-primary"
        >
          The vendor's account detail were pulled from your uploaded document.
        </div>
      </div>
      <form class="grid grid-cols-1 gap-x-5 gap-y-5">
        <div class="mb-1 text-sm font-medium text-primary">
          <p class="pb-4">Vendor type</p>
          <div class="flex items-center gap-x-20">
            <label
              for="business"
              class="inline-flex cursor-pointer items-center gap-x-1"
            >
              <input
                id="business"
                v-model="vendorType"
                type="radio"
                value="business"
              />
              Business
            </label>
            <label
              for="personal"
              class="inline-flex cursor-pointer items-center gap-x-1"
            >
              <input
                id="personal"
                v-model="vendorType"
                type="radio"
                value="personal"
                placeholder="personal"
              />
              Sole trader / Individual
            </label>
          </div>
        </div>
        <div>
          <Label
            for="vendor-name"
            class="mb-1 text-sm font-medium text-[#132248]"
            >Vendor name</Label
          >
          <template v-if="vendorType === 'business'">
            <search-input-advance
              background-color="#ffffff"
              :no-results="noResults"
              :options="companyHouse"
              :is-searching="isLoading"
              :input_name="localFormData.name"
              variant="default"
              @input="handleInput"
              @selected-option="getFormData($event)"
              @manual-input="selectedInput($event)"
            />
          </template>

          <template v-if="vendorType === 'personal'">
            <div>
              <Input
                id="bank-name"
                v-model="localFormData.name"
                type="text"
                placeholder="Enter vendor name"
              /></div
          ></template>
          <!-- <span class="mt-1.5 block text-sm text-red-600"
            >Your recipient's bank has a different name listed for these account
            details. Please double check before adding this vendor.</span
          >
          <span class="mt-1.5 block text-sm text-red-600"
            >Your recipient's bank has a slightly different name listed for
            these account details: <span class="font-semibold">Aelia ltd</span>
          </span> -->
        </div>
        <!-- <div>
          <Label for="bank-name" class="mb-1 text-sm font-medium text-[#132248]"
            >Vendor bank name</Label
          >
          <Input
            id="bank-name"
            v-model="localFormData.bank_name"
            type="text"
            placeholder="Enter bank name"
          />
        </div> -->
        <div v-if="false">
          <Label for="sort-code" class="mb-1 text-sm font-medium text-[#132248]"
            >Vendor sort code</Label
          >
          <div>
            <Input
              id="sort-code"
              v-model="localFormData.sort_code"
              v-cleave="sortCodeMask"
              maxlength="8"
              autocomplete="off"
              type="text"
              placeholder="Enter sort code"
            />
          </div>
        </div>
        <BaseInput
          ref="sortCodeComponent"
          v-model="localFormData.sort_code"
          label="Vendor sort code"
          placeholder="Enter sort code"
          :max-length="8"
        />
        <div class="pb-3">
          <Label
            for="account-number"
            class="mb-1 text-sm font-medium text-[#132248]"
            >Vendor account number</Label
          >
          <div>
            <Input
              id="account-number"
              v-model="localFormData.account_number"
              v-cleave="accountNumberMask"
              maxlength="8"
              autocomplete="off"
              type="text"
              placeholder="Enter account number"
            />
          </div>
          <!-- <span class="mt-1.5 block text-sm text-red-600"
            >This account number does not exist. Please check the sort code and
            account number you have entered.</span
          > -->
        </div>
        <template v-if="shouldCreateOrUpdateVendor === 'create'">
          <hr />
          <div>
            <Label
              for="vendor-email"
              class="mb-1 text-sm font-medium text-[#132248]"
              >Vendor email (optional)</Label
            >
            <Input
              id="vendor-email"
              v-model="localFormData.email"
              type="text"
              placeholder="Enter vendor email"
            />
          </div>
        </template>
        <div
          v-if="confirmPayeeResult && !confirmPayeeResult.is_full_match"
          class="mb-10"
        >
          <div class="mb-1.5">
            <Label
              for="invoice"
              class="col-span-6 flex items-center gap-1 pt-3 text-[14px] font-medium leading-[24px] text-primary sm:col-span-3"
              >Proof of account

              <TooltipProvider>
                <Tooltip :delay-duration="0">
                  <TooltipTrigger> <PhQuestion size="20" /> </TooltipTrigger>
                  <TooltipContent class="max-w-xs space-y-1 p-3 text-xs">
                    <p class="font-bold text-[#344054]">
                      Why is a proof of account needed?
                    </p>
                    <p class="text-[#667085]">
                      A proof of account is needed to ensure that the vendor you
                      are creating actually exists. We will use the document you
                      upload to verify the vendor.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </Label>
            <p class="text-xs text-[#667085]">
              We accept invoices, purchase orders, receipts from this vendor
            </p>
          </div>
          <template v-if="!proofOfAccountFile">
            <FileUploader
              :multiple="false"
              @files="handleFile"
              @rejections="handleFileRejections"
            />
            <p v-if="invalidFile" class="mt-1 text-xs text-red-500">
              Invalid file
            </p>
          </template>
          <div
            v-else
            class="flex items-center justify-between rounded-[6px] border border-[#E2E8F0] px-4 py-3"
          >
            <div class="flex items-center gap-2">
              <img :src="UploadImg" alt="" class="w-6" />
              <p class="flex items-center text-[#475569]">
                <span
                  class="inline-block max-w-[12.5rem] overflow-hidden truncate"
                >
                  {{ getFileNameAndExtension(proofOfAccountFile.name)[0] }}
                </span>
                <span>
                  {{
                    '.' + getFileNameAndExtension(proofOfAccountFile.name)[1]
                  }}</span
                >
              </p>
            </div>
            <button
              @click="
                () => {
                  proofOfAccountFile = undefined
                  invalidFile = false
                }
              "
            >
              <PhTrash :size="20" class="text-[#EF4444]" />
            </button>
          </div>
        </div>
        <MovingLoader v-if="isConfirming" :duration="2500" />

        <div
          v-if="validateError"
          class="border-l-2 px-3 text-xs font-medium text-primary"
        >
          The sort code and account number do not match. Please check in with
          the supplier to confirm before going ahead,Payments to this supplier
          could go to the wrong account and we may not be able to recover the
          money.
        </div>
        <Accordion
          type="single"
          collapsible
          class="hidden rounded-md border px-4"
        >
          <AccordionItem value="item-1" class="border-b-0">
            <AccordionTrigger
              class="py-2 text-base font-medium text-primary hover:no-underline"
              >Vendor business address</AccordionTrigger
            >

            <AccordionContent>
              <div class="pb-5">
                <Label
                  for="address"
                  class="mb-1 text-sm font-medium text-[#132248]"
                  >Address</Label
                >
                <Input
                  id="address"
                  v-model="localFormData.address"
                  type="text"
                  placeholder="Enter address"
                />
              </div>
              <div class="pb-5">
                <Label
                  for="city"
                  class="mb-1 text-sm font-medium text-[#132248]"
                  >City</Label
                >
                <Input
                  id="city"
                  v-model="localFormData.city"
                  type="text"
                  placeholder="Enter city"
                />
              </div>

              <div class="pb-5">
                <Label
                  for="postal-code"
                  class="mb-1 text-sm font-medium text-[#132248]"
                  >Postal code</Label
                >
                <Input
                  id="postal-code"
                  v-model="localFormData.postal_code"
                  type="text"
                  placeholder="Enter Postal code"
                />
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </form>
    </div>
  </ScrollArea>
</template>

<script setup lang="ts">
import { PhQuestion, PhTrash } from '@phosphor-icons/vue'
import { useMutation } from '@tanstack/vue-query'
import { useDebounceFn, watchImmediate } from '@vueuse/core'
import axios from 'axios'
import Cleave from 'cleave.js'
import type { CleaveOptions } from 'cleave.js/options'
import _ from 'lodash'
import { useProfileStore } from '@/stores/profile'
import { ScrollArea } from '@/components/ui/scroll-area'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import SearchInputAdvance from '@/components/shared/search-input-advance.vue'
import UploadImg from '@/assets/images/upload.png'
import { useToast } from '~/components/ui/toast'

import type {
  Invoice,
  OCRType,
  SearchBeneficiaryData,
} from '@/shared/interfaces'
import type BaseInput from '~/components/Base/BaseInput.vue'
import MovingLoader from '~/components/loaders/MovingLoader.vue'
import TooltipContent from '~/components/ui/tooltip/TooltipContent.vue'
import TooltipProvider from '~/components/ui/tooltip/TooltipProvider.vue'
import FileUploader, {
  type RejectedFileAndReasons,
} from '~/components/uploads/FileUploader.vue'
import { getFileNameAndExtension } from '~/lib/utils'
import type { ConfirmPayeeDetailsResponse } from '~/types/apiResponse/confirmationOfPayee.response'

const localFormDefaults = {
  type: 'business',
  name: '',
  email: '',
  phone_number: '',
  sort_code: '',
  account_number: '',
  address: '',
  city: '',
  postal_code: '',
  bank_name: '',
}

const companyHouse = ref<any[]>([])
const isLoading = ref(false)
const noResults = ref(false)
const companyData = ref<SearchBeneficiaryData>({})
const profile = useProfileStore()
const validateError = ref(false)
const vendorType = ref<'business' | 'personal'>('business')
const { toast } = useToast()
const billStore = useBillsStore()

defineProps<{ shouldCreateOrUpdateVendor: 'create' | 'update' }>()
interface Emits {
  (e: 'form-data', v: any): void
  (e: 'validation-result', v: ConfirmPayeeDetailsResponse | undefined): void
  (e: 'proof_of_account_file', v: File | undefined): void
  (e: 'force_allow', v: boolean): void
}

interface IForm {
  type: string
  name: string
  email: string
  phone_number: string
  sort_code: string
  account_number: string
  address: string
  city: string
  postal_code: string
  bank_name: string
}

const localFormData = ref<IForm>({
  type: vendorType.value,
  name: '',
  email: '',
  phone_number: '',
  sort_code: '',
  account_number: '',
  address: '',
  city: '',
  postal_code: '',
  bank_name: '',
})

const _sortCodeAndAccNumber = computed(() => {
  const total =
    localFormData.value.account_number + localFormData.value.sort_code
  return total
})

const ocrData = computed(() => billStore.ocrData)
const selectedBill = computed(() => billStore.selectedBill)
const vendorPrefill = computed(() => billStore.vendorPrefill)
const { vendorAccountPrefill } = storeToRefs(billStore)
const { organisationId } = storeToRefs(useProfileStore())

const { $lenkieBankingApi, $api } = useNuxtApp()

const orgId = computed(() => profile?.data?.organisation?.id)

const emit = defineEmits<Emits>()

const handleInput = _.debounce(async (event) => {
  try {
    isLoading.value = true
    const { data: response } =
      await $api.banking.beneficiaries.searchBeneficaries(
        event.target.value,
        orgId.value,
      )
    const companyHouseResults = response.result.filter(
      (el: any) => el.source !== 'BothLenkieAndCompanyHouse',
    )
    companyHouse.value = companyHouseResults
    noResults.value = companyHouseResults.length === 0
  } catch (error: any) {
  } finally {
    isLoading.value = false
  }
}, 500)

const getFormData = ($event: any) => {
  companyData.value = $event
  localFormData.value.name =
    companyData.value.beneficiary?.trading_name ||
    companyData.value.beneficiary?.name ||
    ''
  localFormData.value.address =
    companyData.value.beneficiary?.address?.street_address || ''
  localFormData.value.city = companyData.value.beneficiary?.address?.town || ''
  localFormData.value.postal_code =
    companyData.value.beneficiary?.address?.postal_code || ''
}

const selectedInput = ($event: any) => {
  vendorType.value = 'personal'

  if (vendorPrefill.value === null && $event) {
    localFormData.value.name = $event
  }
}

const emitFormData = (data: IForm) => {
  if (data.sort_code !== '' && data.account_number !== '' && data.name) {
    data.type = vendorType.value
    emit('form-data', data)
  }
}

watch(
  () => localFormData.value,
  (newVal) => {
    emitFormData(newVal)
  },
  { deep: true },
)

// watch(
//   () => ({
//     ...localFormData.value,
//     sort_code: localFormData.value.sort_code,
//     account_number: localFormData.value.account_number,
//   }),
//   (newValues, oldValues) => {
//     // Check if localFormData as a whole has changed
//     if (JSON.stringify(newValues) !== JSON.stringify(oldValues)) {
//       emitFormData(localFormData.value)
//     }

//     // Check if sort_code or account_number has changed
//     if (
//       newValues.sort_code !== oldValues.sort_code ||
//       newValues.account_number !== oldValues.account_number
//     ) {
//       if (sortCodeAndAccNumber.value.length === 16) {
//         verifySortcodeAndAccountNumber()
//       } else {
//         console.log(sortCodeAndAccNumber.value)
//       }
//     }
//   },
// )

const _verifySortcodeAndAccountNumber = async () => {
  try {
    validateError.value = false
    const sortcode = localFormData.value.sort_code.replaceAll('-', '')
    const { data } = await $lenkieBankingApi.post(
      `/BankAccountValidations?sortCode=${sortcode}&accountNumber=${localFormData.value.account_number}`,
    )
    if (data.is_correct !== true) {
      validateError.value = true
      toast({
        title: 'This account cannot be validated',
        description: ' ',
        variant: 'default',
      })
    }
  } catch (error) {
    validateError.value = true
    // toast({
    //   title: 'This account cannot be validated',
    //   description:
    //     ' The sort code and account number do not match. Please  check in with the supplier to confirm before going ahead,Payments to this supplier could go to the wrong account and we may not be able to recover the money.',
    //   variant: 'default',
    // })
  }
}

const updateVendorOCR = (data: OCRType) => {
  if (data.beneficiary.name || data.beneficiary.trading_name) {
    localFormData.value.name =
      data.beneficiary.name || data.beneficiary.trading_name

    if (vendorType.value === 'business') {
      handleInput({
        target: {
          value: localFormData.value.name,
        },
      })
    }
  }

  if (
    data.beneficiary?.account?.bank &&
    data.beneficiary?.account?.bank.name !== 'null' &&
    data.beneficiary?.account?.bank.name !== null
  ) {
    localFormData.value.bank_name = data.beneficiary?.account?.bank.name
  }

  if (data.beneficiary && data.beneficiary.email_address) {
    localFormData.value.email = data.beneficiary.email_address
  }

  if (data.beneficiary?.account?.uk_bank_details) {
    if (
      data.beneficiary?.account?.uk_bank_details?.account_number !== 'null' &&
      data.beneficiary?.account?.uk_bank_details?.account_number !== null
    ) {
      localFormData.value.account_number =
        data.beneficiary?.account?.uk_bank_details?.account_number
    }

    if (
      data.beneficiary?.account?.uk_bank_details?.sort_code !== 'null' &&
      data.beneficiary?.account?.uk_bank_details?.sort_code !== null
    ) {
      localFormData.value.sort_code =
        data.beneficiary?.account?.uk_bank_details?.sort_code
    }
  }
}

const updateBillOcr = (bill: Invoice) => {
  if (
    bill.beneficiary?.account?.bank &&
    bill.beneficiary?.account?.bank.name !== 'null' &&
    bill.beneficiary?.account?.bank.name !== null
  ) {
    localFormData.value.bank_name = bill.beneficiary?.account?.bank.name
  }

  if (bill.beneficiary?.account?.uk_bank_details) {
    if (
      bill.beneficiary?.account?.uk_bank_details?.account_number !== 'null' &&
      bill.beneficiary?.account?.uk_bank_details?.account_number !== null
    ) {
      localFormData.value.account_number =
        bill.beneficiary?.account?.uk_bank_details?.account_number
    }

    if (
      bill.beneficiary?.account?.uk_bank_details?.sort_code !== 'null' &&
      bill.beneficiary?.account?.uk_bank_details?.sort_code !== null
    ) {
      localFormData.value.sort_code =
        bill.beneficiary?.account?.uk_bank_details?.sort_code
    }
  }
}

watch(
  () => ocrData.value,
  (newVal) => {
    if (newVal !== null && vendorPrefill.value !== null) {
      updateVendorOCR(newVal)
    } else {
      localFormData.value = localFormDefaults
    }
  },
  { immediate: true, deep: true },
)

watch(
  () => selectedBill.value,
  (newVal) => {
    if (newVal !== null && vendorPrefill.value !== null) {
      updateBillOcr(newVal)
    }

    if (newVal === null && ocrData.value === null) {
      localFormData.value = localFormDefaults
    }
  },
  { immediate: true, deep: true },
)

watch(
  () => vendorPrefill.value,
  (newVal) => {
    if (newVal !== null) {
      localFormData.value.name = newVal

      if (vendorType.value === 'business') {
        handleInput({
          target: {
            value: localFormData.value.name,
          },
        })
      }
    }
  },
  { immediate: true, deep: true },
)

watch(
  vendorAccountPrefill,
  (newVal) => {
    if (!newVal) return

    const { account_number, sort_code, bank_name } = newVal
    localFormData.value.account_number = account_number || ''
    localFormData.value.sort_code = sort_code || ''
    localFormData.value.bank_name = bank_name || ''
  },
  { immediate: true },
)

onBeforeUnmount(() => {
  billStore.$patch({ vendorAccountPrefill: null, vendorPrefill: null })
})

const sortCodeComponent = ref<InstanceType<typeof BaseInput>>()

const initializeInputsForVCleave = (
  component: Ref<InstanceType<typeof BaseInput> | undefined>,
  mask: CleaveOptions,
) => {
  if (component.value && component.value.inputRef) {
    const inputRef = component.value.inputRef
    return new Cleave(inputRef, mask)
  }
}
onMounted(() => {
  initializeInputsForVCleave(sortCodeComponent, sortCodeMask)
})

const canFetchPayeeConfirmation = function (
  sort_code: string,
  name: string,
  account_number: string,
) {
  const isNumeric = (value: string) => /^\d+$/.test(value)
  return (
    name.trim() !== '' &&
    isNumeric(sort_code) &&
    isNumeric(account_number) &&
    account_number.length === 8 &&
    sort_code.length === 6
  )
}

const {
  mutate: confirmFn,
  data: confirmPayeeData,
  reset,
  isPending: isConfirming,
} = useMutation({
  mutationFn: $api.banking.confirmationOfPayee.confirmPayeeDetails,
  mutationKey: computed(() => [
    {
      ...localFormData,
      vendorType: vendorType.value,
      organisationId: organisationId.value,
    },
  ]),
})

const confirmPayeeResult = computed(() => {
  if (!confirmPayeeData.value) return null
  return confirmPayeeData.value.data
})

watchImmediate(
  confirmPayeeResult,
  (newVal) => {
    if (!newVal) {
      emit('validation-result', undefined)
    } else {
      emit('validation-result', newVal)
    }
  },
  { deep: true },
)

function attempConfirmStuff() {
  if (!organisationId.value) return
  const { account_number, sort_code, name } = localFormData.value

  const sortCode = sort_code.replaceAll('-', '')

  if (canFetchPayeeConfirmation(sortCode, name, account_number)) {
    confirmFn(
      {
        account_number,
        sort_code: sortCode,
        is_business_account: vendorType.value === 'business',
        name,
        organisationId: organisationId.value,
      },
      {
        onError(error) {
          if (axios.isAxiosError(error)) {
            emit('force_allow', true)
          }
        },
      },
    )
  }
}

const debouncedAttemptConfirmStuff = useDebounceFn(attempConfirmStuff, 500)

const proofOfAccountFile = ref<File>()

watch(
  localFormData,
  () => {
    emit('force_allow', false)
    reset()
    debouncedAttemptConfirmStuff()
  },
  { deep: true, immediate: true },
)

watchImmediate(vendorType, () => {
  emit('force_allow', false)
  reset()
  debouncedAttemptConfirmStuff()
})

watchImmediate(
  proofOfAccountFile,
  (newVal) => {
    emit('proof_of_account_file', newVal)
  },
  { deep: true },
)

const invalidFile = ref(false)
function handleFile(files: File[]) {
  invalidFile.value = true
  const file = files[0]
  proofOfAccountFile.value = file
}

function handleFileRejections(_rejections: RejectedFileAndReasons[]) {
  if (_rejections.length) {
    invalidFile.value = true
  }
}
</script>
