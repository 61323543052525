<template>
  <div class="group/drop relative w-full">
    <div class="relative">
      <SearchIcon class="absolute left-3 top-[9px] h-5 w-5" />
      <Input
        id="vendor"
        ref="inputRef"
        type="text"
        :placeholder="placeholder || 'Enter vendor name'"
        class="mt-1.5 pl-10"
        @focus="openMenu"
        @input="inputValue"
      />

      <div
        v-if="isSearching"
        class="absolute right-3 top-1/2 -translate-y-1/2 rounded-full"
      >
        <the-icon
          icon-name="spinner"
          size="s"
          class="animate-spin text-gray-400"
        />
      </div>
    </div>

    <template v-if="showOptions">
      <div
        class="absolute top-12 z-10 w-full rounded-md border border-gray-200 bg-white py-1 shadow-sm"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex="{-1}"
      >
        <ScrollArea
          type="always"
          :class="options.length > 5 ? 'h-[200px]' : 'h-auto'"
        >
          <button
            v-for="(option, idx) in options"
            :key="option.id"
            type="button"
            class="flex w-full flex-col border-b px-3 py-1 text-sm text-primary last:border-0 hover:bg-gray-100 hover:text-black"
            role="menuitem"
            data-class=""
            :data-test-id="`vendor-search-item-${idx}`"
            @click="handleClick(option)"
          >
            <span class="py-[6px] lowercase first:first-letter:uppercase">{{
              option.beneficiary.trading_name
            }}</span>
          </button>
        </ScrollArea>
        <div class="w-full border-t bg-white px-4 py-2">
          <div class="text-sm text-primary">Couldn't find your vendor?</div>
          <Button
            variant="secondary"
            type="button"
            class="mt-2 w-full"
            @click="addManually"
            >Add as an individual / sole trader</Button
          >
        </div>
      </div>
    </template>
    <template v-if="noResults && variant === 'new-bill'">
      <div
        class="mt-1 w-full rounded-md border border-gray-200 bg-white p-4 shadow-sm"
      >
        <the-icon icon-name="exclamation" size="s" class-name="mx-auto" />
        <div class="mt-1 text-center text-sm font-medium text-primary">
          Vendor not found
        </div>
        <div v-if="variant !== 'new-bill'" class="mt-3.5 border-t pt-3.5">
          <Button
            type="button"
            variant="outline"
            class="w-full"
            @click.prevent="addManually"
            >Add manually</Button
          >
        </div>

        <div v-if="variant === 'new-bill'" class="mt-3.5 border-t pt-3.5">
          <Button
            type="button"
            variant="outline"
            class="w-full"
            @click="handleOpenCreateVendor"
            >Add new vendor</Button
          >
        </div>
      </div>
    </template>
    <template v-if="noResults && variant !== 'new-bill'">
      <div
        role="alert"
        class="mt-2 flex cursor-pointer items-start gap-2 rounded-[0.25rem] bg-[#FFF7ED] px-[10.5px] py-2 text-sm font-medium text-[#C2410C]"
        @click.prevent="addManually"
      >
        <AlertTriangleIcon class="h-5 w-5 flex-shrink-0 text-current" />
        <p>
          We cannot find this vendor in the UK business directory. Please ensure
          the name is spelt correctly or
          <span class="text-primary underline">
            add as an individual / sole trader
          </span>
        </p>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { watch, ref } from 'vue'
import { SearchIcon, AlertTriangleIcon } from 'lucide-vue-next'

import TheIcon from '@/components/shared/the-icon.vue'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useBillsStore } from '@/stores/bills'

const showOptions = ref(false)
const { $event } = useNuxtApp()
const billStore = useBillsStore()

interface IProps {
  variant: 'default' | 'new-bill'
  placeholder?: string
  backgroundColor?: string
  showResults?: boolean
  options?: any
  noResults: boolean
  isSearching?: boolean
  // eslint-disable-next-line vue/prop-name-casing
  input_name: string
}

const props = defineProps<IProps>()

const emits = defineEmits([
  'selected-option',
  'close-no-results',
  'manual-input',
])

watch(
  () => props.options,
  () => {
    if (props.options?.length) {
      showOptions.value = true
    } else {
      showOptions.value = false
    }
  },
)

const inputRef = ref()
const manualInputValue = ref()

function updateInputValue(inputElement: HTMLInputElement, value: string) {
  inputElement.value = value
}

onMounted(() => {
  updateInputValue(inputRef.value.$el, props.input_name)
})

watchEffect(() => {
  if (inputRef.value?.$el) {
    updateInputValue(inputRef.value.$el, props.input_name)
  }
})
const inputValue = (event: any) => {
  manualInputValue.value = event.target.value
}
const addManually = () => {
  emits('manual-input', manualInputValue.value)
}

const openMenu = () => {
  if (props.options.length) {
    showOptions.value = true
  }
}

const handleClick = (option: any) => {
  showOptions.value = false
  emits('selected-option', option)

  if (option.beneficiary)
    updateInputValue(inputRef.value.$el, option.beneficiary.trading_name)
}

const handleOpenCreateVendor = () => {
  $event('open:vendor', { trigger: true, createNewVendorForBill: true })
  billStore.setVendorPrefill(manualInputValue.value)
}
</script>
