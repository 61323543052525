import { defineStore } from 'pinia'

interface DefaultStateType {
  data: any[]
  allCountries: any[]
  fetched: boolean
  fetchedAll: boolean
}

const defaultState: () => DefaultStateType = () => ({
  data: [],
  allCountries: [],
  fetched: false,
  fetchedAll: false,
})

export const useCountries = defineStore('countries', {
  state: defaultState,
  actions: {
    async getCountries() {
      try {
        const { $lenkieBankingApi } = useNuxtApp()
        const countries = (
          await $lenkieBankingApi.get('/Payments/SupportedCountries')
        ).data
        this.data = countries
      } catch (error) {
      } finally {
        this.fetched = true
      }
    },
    async getAllCountries() {
      try {
        const { $lenkieApi } = useNuxtApp()
        const data = (await $lenkieApi.get('/Countries')).data
        this.allCountries = data
      } catch (error) {
      } finally {
        this.fetchedAll = true
      }
    },
  },
})
