interface FileUploadResponse {
  failure_reasons: null
  is_success: boolean
  result: {
    relative_paths: string[]
  }
  error_message: null | string
}

export function useFileUploader() {
  const { $lenkieBankingApi } = useNuxtApp()

  async function upload(
    payload: {
      organisationId: string
      uploadType: string
      files: File[]
    },
    uploadProgress?: Ref<number>,
  ) {
    const { organisationId, uploadType, files } = payload

    const formData = new FormData()
    files.forEach((file) => {
      formData.append('files', file)
    })

    const { status, data } = await $lenkieBankingApi.post<FileUploadResponse>(
      `/FileUpload/organisation/${organisationId}/upload/${uploadType}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress(progressEvent) {
          if (progressEvent.total && uploadProgress) {
            uploadProgress.value = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100,
            )
          }
        },
      },
    )

    if (status === 200) {
      return data.result.relative_paths
    }
  }

  return {
    upload,
  }
}
