<script setup lang="ts">
import { keepPreviousData, useQuery } from '@tanstack/vue-query'
import { useDebounce } from '@vueuse/core'
import { PhSpinner } from '@phosphor-icons/vue'
import { ChevronRight, SearchIcon } from 'lucide-vue-next'
import TheIcon from '@/components/shared/the-icon.vue'
import type { BillStatusType, Invoice } from '~/shared/interfaces'
import { currencySymbol } from '@/shared/utils'
import { BILLS_QUERY_KEYS } from '~/constants/queryKeys/billsKeys'
import type { GetBillsQueryParams } from '~/types/apiPayload/bills.payload'

const statusColors = {
  Draft: { text: 'text-yellow-700', bg: 'bg-yellow-400', value: 'Draft' },
  ReadyForPayment: {
    text: 'text-blue-700',
    bg: 'bg-blue-400',
    value: 'Ready for payment',
  },
  Failed: {
    text: 'text-red-700',
    bg: 'bg-red-400',
    value: 'Failed',
  },
  AwaitingApproval: {
    text: 'text-[#f9f9f9]',
    bg: 'bg-[#F97316]',
    value: 'Awaiting approval',
  },
  Approved: {
    text: 'text-lime-700',
    bg: 'bg-lime-400',
    value: 'Approved',
  },
  Canceled: {
    text: 'text-slate-700',
    bg: 'bg-slate-400',
    value: 'Canceled',
  },
  Rejected: {
    text: 'text-red-700',
    bg: 'bg-red-400',
    value: 'Rejected',
  },
  Scheduled: {
    text: 'text-purple-700',
    bg: 'bg-purple-400',
    value: 'Scheduled',
  },
  Paid: {
    text: 'text-green-700',
    bg: 'bg-green-400',
    value: 'Paid',
  },
  Processing: {
    text: 'text-sky-700',
    bg: 'bg-sky-400',
    value: 'Processing',
  },
  None: {
    text: 'text-gray-700',
    bg: 'bg-gray-400',
    value: 'None',
  },
}

interface Emits {
  (e: 'selected-bill-from-search', v: Invoice): void
}
const emit = defineEmits<Emits>()

const { $api } = useNuxtApp()
const searchTerm = ref('')

const getBillStatusClasses = (billStatus: BillStatusType | undefined) => {
  const statusInfo = statusColors[billStatus || 'None']

  return `${statusInfo.text} ${statusInfo.bg}`
}

const profileStore = useProfileStore()
const organisationId = computed(() => profileStore?.data?.organisation?.id)

const PageSize = ref(10)
const showDropdown = ref(true)

const FILTER_DEFAULTS: GetBillsQueryParams = {
  Page: 1,
  PageSize: PageSize.value,
  CurrencyCode: null,
}

const debouncedSearchTerm = useDebounce(searchTerm, 500)
const filters = reactive(FILTER_DEFAULTS)

watch(
  () => debouncedSearchTerm.value,
  (newVal) => {
    filters.Search = newVal
    filters.Page = 1
  },
)

const { data: billsResponse, isFetching: isFetchingBills } = useQuery({
  queryKey: BILLS_QUERY_KEYS.GET_ALL_BILLS(filters),
  queryFn: () =>
    $api.banking.bills.getAllBills({
      OrganisationId: organisationId.value,
      ...filters,
    }),
  placeholderData: keepPreviousData,
  enabled: () => !!organisationId.value && debouncedSearchTerm.value !== '',
  select(data) {
    return data.data.result
  },
})

const bills = computed(() => billsResponse.value?.data || [])

const goToNextPage = () => {
  if (!filters.PageSize) {
    return
  }

  filters.PageSize = filters.PageSize + 10
}

function handleEmitSelectedBill(bill: Invoice) {
  emit('selected-bill-from-search', bill)
  showDropdown.value = false
}
</script>

<template>
  <div
    v-click-outside="
      () => {
        showDropdown = false
      }
    "
    class="relative ml-auto mr-5 w-full max-w-[250px] xl:max-w-[350px]"
  >
    <BaseInput
      v-model="searchTerm"
      placeholder="Search for invoices, vendors..."
      bg-color="#fff"
      @click="showDropdown = true"
    >
      <template #leftContent>
        <SearchIcon class="ml-3 h-5 w-5" />
      </template>
      <template v-if="isFetchingBills" #rightContent>
        <the-icon
          icon-name="spinner"
          size="s"
          class="mr-3 animate-spin text-gray-400"
        />
      </template>
    </BaseInput>
    <div
      v-if="
        bills && bills.length > 0 && debouncedSearchTerm !== '' && showDropdown
      "
      class="absolute left-0 top-12 max-h-80 w-full overflow-x-hidden rounded-sm border bg-white shadow-lg"
    >
      <button
        v-for="bill in bills"
        :key="bill.id"
        type="button"
        class="flex w-full items-center justify-between border-b px-4 py-2 hover:bg-gray-50"
        @click="handleEmitSelectedBill(bill)"
      >
        <div class="flex items-center">
          <the-icon icon-name="file" size="s" fill />
          <div class="ml-2">
            <div class="flex items-center">
              <div
                class="line-clamp-1 text-left text-xs font-medium capitalize text-primary"
              >
                {{ bill.beneficiary.name.toLocaleLowerCase() || '-' }}
              </div>
            </div>
            <div class="text-left text-[10px] text-slate-500">
              # {{ bill.invoice_id || '-' }}
            </div>
          </div>
        </div>
        <div class="text-right">
          <div>
            <div
              class="ml-auto flex w-[max-content] items-center gap-x-1 rounded-full text-xs text-primary"
            >
              <div
                :class="getBillStatusClasses(bill?.bill_status)"
                class="h-1.5 w-1.5 rounded-full"
              ></div>
              {{ statusColors[bill.bill_status || 'None'].value || '-' }}
            </div>
          </div>
          <div class="text-xs font-bold text-primary">
            {{ currencySymbol[bill.currency || 'Other']
            }}{{
              bill.amount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || '-'
            }}
          </div>
        </div>
      </button>

      <div
        v-if="billsResponse?.has_more"
        class="flex w-full justify-center px-4 py-3"
      >
        <Button
          type="button"
          variant="outline"
          :disabled="isFetchingBills"
          size="sm"
          class="border-primary"
          @click="goToNextPage"
          >Show more results
          <ph-spinner
            v-if="isFetchingBills"
            :size="24"
            class="ml-1 animate-spin duration-1000"
          />
          <ChevronRight v-else class="ml-1 h-4 w-4" />
        </Button>
      </div>
    </div>
    <div
      v-if="
        bills &&
        bills.length === 0 &&
        debouncedSearchTerm !== '' &&
        !isFetchingBills &&
        showDropdown
      "
      class="custom-scrollbar absolute top-12 flex h-80 w-full items-center justify-center overflow-x-hidden rounded-sm border bg-white shadow-lg"
    >
      <div class="text-center">
        <div class="text-base font-semibold text-primary">No results found</div>
        <p class="px-10 text-xs text-slate-500">
          We could not find what you are looking for, try something else
        </p>
      </div>
    </div>
  </div>
</template>
