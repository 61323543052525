<script setup lang="ts">
import { ListIcon } from 'lucide-vue-next'
import bulkbillsvideo from '@/assets/videos/bulk-bills-upload-video.webm'
import { useToast } from '~/components/ui/toast'
import { downloadFile, getFileType } from '~/lib/utils'
import { getMailBoxQuery } from '~/queries/mail.query'
const { invalidateAllBillListQueries } = useQueryUtilitiesFns()

interface Emits {
  (e: 'success'): void
  (e: 'isBusy', v: boolean): void
}
const { $api, $event } = useNuxtApp()
const { toast } = useToast()
const emit = defineEmits<Emits>()
const router = useRouter()
const { data: mailBoxData } = getMailBoxQuery()

function handleSuccess() {
  emit('success')

  invalidateAllBillListQueries()

  $event('fetch:bills', { trigger: true, params: { Page: 1 } })
  router.push({ path: '/bills', query: { page: 1 } })
}

function handleIsBusy(val: boolean) {
  emit('isBusy', val)
}

const fetchingTemplate = ref(false)
async function fetchTemplate(type: 'xlsx' | 'csv' | 'txt') {
  try {
    const fileTypeToFetch =
      type === 'xlsx'
        ? 'Excel'
        : type === 'csv' || type === 'txt'
          ? 'Csv'
          : null

    fetchingTemplate.value = true
    const { headers, data } =
      await $api.banking.bills.downloadTemplateForBulkBills(fileTypeToFetch!)
    const blob = new Blob([data], { type: headers['content-type'] })
    const file = new File([blob], 'bills', {
      type: getFileType(type),
    })
    downloadFile(file)

    toast({
      title: 'File download',
      description: 'Template file downloaded',
    })
  } catch (error) {
    toast({
      title: 'Error',
      description: 'Error occured while fetching file',
    })
  } finally {
    fetchingTemplate.value = false
  }
}
</script>

<template>
  <div>
    <div class="grid gap-7 md:grid-cols-[0.45fr,0.55fr]">
      <div class="flex flex-col gap-5">
        <h3 class="flex items-center gap-2 border-b pb-3 font-bold">
          <span
            class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-[#F3F7FF] text-[#3F558E]"
          >
            <ListIcon class="h-7 w-7" />
          </span>

          <span class="text-xl text-[#1A2D5B]"
            >Create multiple bills in as little as one step</span
          >
        </h3>

        <div>
          <div class="space-y-5 text-sm text-primary">
            <p v-if="mailBoxData">
              You can forward multiple invoices via email to your Lenkie mailbox
              -
              <a
                :href="`mailto:${mailBoxData.address}`"
                class="text-[#758EC6] underline-offset-2 hover:underline"
              >
                {{ mailBoxData.address }}
              </a>
              and we will promptly generate the bills for you.
            </p>
            <p>
              In case you don't have any invoices, you can download our template
              file, enter the required information for all the bills you wish to
              create, and upload it. We will then create the bills instantly.
            </p>
          </div>
        </div>

        <div
          class="mt-auto flex items-center gap-6 rounded-[0.25rem] bg-[#F3f7ff] p-4 max-sm:flex-col max-sm:items-stretch"
        >
          <p class="font-normal text-primary">
            We have designed various file templates to assist you in creating
            bulk bills easily.
          </p>
          <div
            class="flex flex-shrink-0 justify-center gap-2 text-lg font-medium text-primary"
            :class="[
              {
                'pointer-events-none opacity-50': fetchingTemplate,
              },
            ]"
          >
            <SharedTheIcon
              v-if="fetchingTemplate"
              icon-name="spinner"
              class="animate-spin"
              :class="[
                {
                  'opacity-0': !fetchingTemplate,
                  'pointer-events-none opacity-50': fetchingTemplate,
                },
              ]"
            />
            <button class="underline" @click="fetchTemplate('csv')">CSV</button>
            <span class="text-[#9CA3AF]">|</span>
            <button class="underline" @click="fetchTemplate('xlsx')">
              XLSX
            </button>
          </div>
        </div>
      </div>
      <div class="hidden items-center justify-center bg-[#EFEFEF] md:flex">
        <video :src="bulkbillsvideo" muted autoplay loop></video>
      </div>
    </div>
    <div class="mt-8">
      <ModulesBillsBulkBillsFileUpload
        show-template-links
        @success="handleSuccess"
        @is-busy="handleIsBusy"
      />
    </div>
  </div>
</template>
