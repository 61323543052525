<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { PhSpinner } from '@phosphor-icons/vue'
import { Button } from '~/components/ui/button'
import { useToast } from '~/components/ui/toast'
import { getLastItemInArray } from '~/lib/utils'
import type { AcceptOrDeclineCreditFacilityUpdateRequestPayload } from '~/types/apiPayload/creditFacility.payload'
const { $api, $event } = useNuxtApp()
const creditFacilityStore = useCreditFacilityStore()
const { deactivationReasonIds, creditFacilityUpdateRequest, facilityAmount } =
  storeToRefs(creditFacilityStore)
const lastReasonId = computed(() => {
  return getLastItemInArray(deactivationReasonIds.value)
})

let whatUserDecided: string
const { toast } = useToast()

const { organisationId } = storeToRefs(useProfileStore())
// const showBanner = computed(
//   () => lastReasonId.value !== 'credit-facility-update-pending',
// )

const { mutate, isPending: isUpdating } = useMutation({
  mutationFn:
    $api.banking.creditFacilities.acceptOrDeclineCreditFacilityUpdateRequests,
  onSuccess() {
    const message =
      whatUserDecided === 'accept'
        ? 'Your Credit Facility has been updated'
        : 'You declined the Credit Facility update'
    toast({ title: 'Credit facility updated', description: message })
    $event('update:credit-facility', null)
  },
  onError() {
    toast({
      title: 'Error updating credit facility',
      description: 'Failed to update credit facility',
    })
  },
})

function updateRequest(decision: 'accept' | 'decline') {
  let status: AcceptOrDeclineCreditFacilityUpdateRequestPayload

  whatUserDecided = decision

  if (decision === 'accept') {
    status = { id: 'Accepted', status: 'Accepted' }
  } else {
    status = { id: 'Rejected', status: 'Rejected' }
  }
  mutate({ borrowerId: organisationId.value!, status })
}

watch(isUpdating, (newVal) => {
  if (newVal) {
    toast({
      title: 'Please wait...',
      description: 'Please wait while we process your credit facility update.',
    })
  }
})
const isFetchingMandateUrl = ref(false)

const getMandateUrl = async () => {
  if (!organisationId.value) {
    return
  }

  try {
    isFetchingMandateUrl.value = true

    const { status, data } =
      await $api.goCardLess.payments.createGoCardLessMandateAuthorisation(
        organisationId.value,
      )

    if (status === 200) {
      const mandateVerificationUrl = data.result.redirect_authorization_url
      if (mandateVerificationUrl) {
        $event('track:mixpanel', {
          event: 'Start Direct Debit Mandate Verification',
          data: {},
        })
        toast({
          title: 'Redirecting...',
          description: 'Please wait as we redirect you to verify your mandate',
        })
        setTimeout(() => {
          window.location.href = mandateVerificationUrl
          isFetchingMandateUrl.value = false
        }, 700)
      }
    }
  } catch (error) {
    isFetchingMandateUrl.value = false
    toast({
      title: 'Failed to generate mandate url',
      description: error?.response?.data?.error_message || 'Error occured',
      variant: 'destructive',
    })
  }
}
</script>

<template>
  <div
    class="mx-4 border-b bg-[##E2E8F0] py-6 text-left text-sm leading-5 text-[#DC2626]"
  >
    <p
      v-if="
        lastReasonId !== 'credit-facility-update-pending' &&
        lastReasonId !== 'mandate-connection-required'
      "
      class="text-sm font-semibold"
    >
      Your credit facility has been disabled.
    </p>

    <p v-if="lastReasonId === 'bank-account-access-expired'">
      Your facility has been disabled because your bank connection has expired.
      Your access will be restored when you update your bank details on the
      <NuxtLink to="/settings/connections?active=bank-accounts">
        connections page.
      </NuxtLink>
    </p>

    <p v-else-if="lastReasonId === 'bank-account-transaction-download-failed'">
      Your facility has been disabled because your bank connection has expired.
      Your access will be restored when you update your bank details on the
      <NuxtLink to="/settings/connections?active=bank-accounts">
        connections page.
      </NuxtLink>
    </p>

    <p v-else-if="lastReasonId === 'missing-manual-pdf'">
      Your facility has been disabled due to missing bank statements. Send your
      latest monthly bank statements to
      <a href="mailto:hello@lenkie.com">hello@lenkie.com</a> to reactivate your
      facility.
    </p>

    <p v-else-if="lastReasonId === 'credit-facility-update-rejected'">
      Please contact us at
      <a href="mailto:hello@lenkie.com">hello@lenkie.com</a> if you have any
      questions.
    </p>

    <p v-else-if="lastReasonId === 'negative-risk-assessment'">
      Please contact us at
      <a href="mailto:hello@lenkie.com">hello@lenkie.com</a> if you have any
      questions.
    </p>

    <p v-else-if="lastReasonId === 'repayment-failed'">
      Your facility has been disabled because of failed repayments. These
      repayments must be made in full before your facility can be reactivated.
      Please contact <a href="mailto:hello@lenkie.com">hello@lenkie.com</a> with
      any questions.
    </p>

    <p v-else-if="lastReasonId === 'shortage-of-funding'">
      Please contact us at
      <a href="mailto:hello@lenkie.com">hello@lenkie.com</a> if you have any
      questions.
    </p>

    <p v-else-if="lastReasonId === 'temporarily-withheld'">
      Please contact us at
      <a href="mailto:hello@lenkie.com">hello@lenkie.com</a> if you have any
      questions.
    </p>

    <p
      v-else-if="lastReasonId === 'mandate-connection-required'"
      :class="[
        {
          'pointer-events-none opacity-90': isFetchingMandateUrl,
        },
      ]"
    >
      Please connect your direct debit mandate to
      <button class="font-semibold underline" @click="getMandateUrl">
        activate your credit facility
      </button>
      . If you have any questions, please contact us at
      <a href="mailto:hello@lenkie.com">hello@lenkie.com</a>

      <ph-spinner
        v-if="isFetchingMandateUrl"
        class="ml-4 inline animate-spin"
        size="24"
      />
    </p>

    <div
      v-else-if="
        lastReasonId === 'credit-facility-update-pending' &&
        creditFacilityUpdateRequest
      "
      class="flex flex-wrap justify-center gap-x-10 gap-y-4 text-primary"
    >
      <div>
        <p>
          Your active credit facility amount has been changed from
          <span class="font-bold">
            {{
              `${getCurrency(creditFacilityUpdateRequest.currency.code)}${Intl.NumberFormat().format(facilityAmount || 0)}`
            }}
          </span>

          to
          <span class="font-bold">
            {{
              `${getCurrency(creditFacilityUpdateRequest.currency.code)}${Intl.NumberFormat().format(
                creditFacilityUpdateRequest.requested_facility_amount,
              )}`
            }} </span
          >.
        </p>
        Payments cannot be made using the Credit Facility until the change has
        been acknowledged.
      </div>

      <div class="flex items-center gap-4">
        <Button :disabled="isUpdating" @click="updateRequest('accept')"
          >Accept</Button
        >
        <Button
          :disabled="isUpdating"
          variant="ghost"
          class="border border-destructive text-destructive"
          @click="updateRequest('decline')"
          >Decline</Button
        >
      </div>
    </div>

    <p v-else>
      Please contact us at
      <a href="mailto:hello@lenkie.com">hello@lenkie.com</a> if you have any
      questions.
    </p>
  </div>
</template>

<style scoped>
a {
  font-weight: 700;
  text-decoration: underline;
}
</style>
