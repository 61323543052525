<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { useDropzone } from 'vue3-dropzone'
import { useToast } from '~/components/ui/toast'

interface Emits {
  (e: 'success'): void
  (e: 'isBusy', v: boolean): void
}
interface Props {
  showTemplateLinks?: boolean
  size?: 'small' | 'large'
}

withDefaults(defineProps<Props>(), { size: 'small' })
const emit = defineEmits<Emits>()

const { toast } = useToast()
const { $api, $event } = useNuxtApp()
const profileStore = useProfileStore()
const { organisationId } = storeToRefs(profileStore)

const isBusy = ref(false)

const onDrop = (files: File[]) => {
  uploadBulkBillsFile(files[0])
}

const { getRootProps, getInputProps, isDragActive } = useDropzone({
  onDrop,
  accept: '.csv,.xlsx',
  multiple: false,
})

const { mutate: uploadFn, isPending: isUploading } = useMutation({
  mutationFn: $api.banking.bills.importBillsInBulk,
})

function uploadBulkBillsFile(file: File) {
  if (!organisationId.value) return

  emit('isBusy', true)

  uploadFn(
    { file, organisationId: organisationId.value },
    {
      onSuccess(response) {
        const successCount = response.data.total_imported
        const message = `${successCount} ${successCount > 1 ? 'bills' : 'bill'} created successfully`
        toast({
          title: 'Bulk bills uploaded',
          description: message,
        })
        $event('track:mixpanel', {
          event: 'Bulk bills uploaded',
          data: {},
        })
        emit('success')
        emit('isBusy', false)
      },
      onError(err: any) {
        const errMsg =
          err?.response?.data?.error_message ||
          'Error occured while uploading bills file'
        toast({
          title: 'Bills Upload',
          description: errMsg,
          variant: 'destructive',
        })

        emit('isBusy', false)
      },
    },
  )
}
</script>

<template>
  <div>
    <!-- <div v-if="isUploading || isBusy" class="py-5">
      <div class="flex justify-center">
        <SharedTheIcon icon-name="spinner" size="m" class-name="animate-spin" />
      </div>
      <p
        class="mt-4 text-center text-sm font-medium leading-[20px] text-primary"
      >
        Uploading bills file...
      </p>
    </div> -->
    <div class="rounded-lg bg-[#F8F9FA] p-5 text-center">
      <div class="text-base font-bold text-primary">
        Upload your invoices or an updated template
      </div>
      <div
        class="my-4 bg-white"
        :class="[
          {
            'h-60': size === 'large',
            'h-48': size === 'small',
          },
        ]"
      >
        <div
          v-if="isUploading || isBusy"
          class="flex h-full flex-col justify-center gap-4"
        >
          <div class="flex justify-center">
            <SharedTheIcon
              icon-name="spinner"
              size="m"
              class-name="animate-spin"
            />
          </div>
          <p
            class="text-center text-sm font-medium leading-[20px] text-primary"
          >
            Uploading your invoices
          </p>
        </div>
        <div
          v-else
          v-bind="getRootProps()"
          class="mx-auto flex h-full items-center justify-center rounded-lg border"
          :class="[
            {
              'outline outline-4 outline-[#758ec6]': isDragActive,
            },
          ]"
        >
          <div>
            <input v-bind="getInputProps()" />
            <div onclick="open">
              <div class="flex justify-center">
                <Button variant="outline" size="icon" class="rounded-lg">
                  <img src="@/assets/svgs/upload.svg" alt="svg" />
                </Button>
              </div>
              <div class="relative mt-3 flex justify-center">
                <p class="cursor-pointer text-center text-sm leading-[20px]">
                  <span class="font-semibold text-primary"
                    >Click to upload</span
                  >
                  <span class="ml-1 text-gray-600"
                    >or drag and drop <br />

                    <span class="text-xs"
                      >Only <b>.CSV</b> and <b>.XLSX</b> file formats are
                      supported</span
                    >
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
